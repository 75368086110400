
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getAlarmCount, getAlarmList } from '@/api/pushAlarm';
import { getNewAlarmCount } from '@/api/newAlarm';
import { storageKey } from '@/enums/localStorage';
import { getOrderCount } from '@/api/order';
import { getShopDetail } from '@/api/shop';
import { OrderModule } from '@/store/modules/order';
import moment from 'moment';

@Component({
  name: 'SubLayoutHeader',
})
export default class extends Vue {
  mounted() {
    this.getAlarmList();
    this.getNewAlarmCount();
    // this.maxHoldCntCheck();
    this.shopHolidayCheck();
  }

  private newAlarmCount = 0;

  private showPopover = false;

  private alarmCount = 0;

  private alarmList = [];

  private alarmList2: any[][] = [];

  private totalElements = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 5,
  };

  private selectedShop = JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace));

  private maxHoldCntCheck() {
    if (this.selectedShop.maxHoldCnt === 0) {
      this.$message.info('주문 가능 수량이 모두 소진되었습니다. 다른 매장을 선택해 주세요.');
      this.$router.push({ name: 'Map' });
    }
  }

  private async shopHolidayCheck() {
      await getShopDetail(this.selectedShop.idx).then((res) => {
        this.selectedShop = res.data;
        window.localStorage.setItem(storageKey.pickUpPlace, JSON.stringify(this.selectedShop));
      });
    const currentDate = moment().format('YYYY-MM-DD');
    if (this.selectedShop.holidayStartDate <= currentDate && this.selectedShop.holidayEndDate >= currentDate) {
      this.$message.info('해당 상점은 오늘 휴무일입니다. 다른 매장을 선택해 주세요.');
      this.$router.push({ name: 'Map' });
    }
  }

  private getOrderCount() {
    getOrderCount(this.selectedShop.idx, OrderModule.orderForm.week).then((res) => {
      console.log(res.data);
    });
  }

  private getNewAlarmCount() {
    getNewAlarmCount().then((res) => {
      this.newAlarmCount = res.data;
    });
  }

  private getAlarmCount() {
    getAlarmCount().then((res) => {
      this.alarmCount = res.data;
    });
  }

  private async getAlarmList() {
    await getAlarmList(this.listQuery).then((res) => {
      this.alarmList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
    const itemMap: Map<string, any[]> = new Map();
    this.alarmList.forEach((item: any) => {
      const date: string = item.createDate.substring(0, 10); // 날짜 부분만 추출
      if (!itemMap.has(date)) {
        itemMap.set(date, []);
      }
      const itemList: any[] | undefined = itemMap.get(date);
      if (itemList) {
        itemList.push(item);
      }
    });
    this.alarmList2 = Array.from(itemMap.values());
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getAlarmList();
  }

  private handleClickBefore() {
    if (this.$route.name === 'OrderDetail' || this.$route.name === 'Cart') {
      this.$router.push({ name: 'Order' });
    } else if (this.$route.name === 'Order') {
      this.$router.push({ name: 'Home' });
    } else if (this.$route.name === 'Payment') {
      this.$router.push({ name: 'Cart' });
    } else {
      this.$router.go(-1);
    }
  }

  private handleClickHome() {
      this.$router.push({ name: 'Home' });
  }
}
